.btn-option, .btn-option:focus {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(8, 0, 41, 0.06);
    color: rgb(8, 0, 41);
    max-width: 100%;
    min-height: 40px;
    outline: 0px;
    padding: 6px;
    min-width: 30%;
    cursor: pointer;
    opacity: 1;
    border: 1px solid #687179;
    box-shadow: rgb(132 130 140 / 60%) 7px 5px 0px 1px;
}

.btn-option-selected, .btn-option-selected:focus {
    margin-bottom: 10px;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    background-color: rgba(8, 0, 41, 0.06);
    color: rgb(8, 0, 41);
    max-width: 100%;
    min-height: 40px;
    outline: 0px;
    padding: 6px;
    min-width: 30%;
    cursor: pointer;
    opacity: 1;
    border: 2.5px solid #040404;
    font-weight: bold;
    box-shadow: rgb(132 130 140 / 60%) 7px 5px 0px 1px;
}

.explanation-btn, explanation-btn:focus {
    font-size: 9pt;
    font-style: italic;
    font-weight: normal;
}

.opt-key {
    width: 35px;
    text-align: center;
    height: 35px;
    background-color: rgb(47 36 90 / 29%);
    padding: 5px;
}

/* hide arrow on input number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.input-underline {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid black;
    width: 100%;
    min-height: 45px;
    font-size: 20pt;
}

.input-underline-single-select-input {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid black;
    width: 100%;
    height: 35px;
    font-size: 20pt;
}

.input-underline-single-select-input, ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 15pt;
}

.input-underline-single-select-input, :-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-size: 15pt;
}

.input-underline-single-select-input, ::-ms-input-placeholder { /* Microsoft Edge */
  font-size: 15pt;
}

.input-underline-file {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid black;
    width: 100%;
    font-size: 10pt;
    cursor: pointer;
}

.input-underline-sales-area {
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 2px solid black;
    width: 100%;
    font-size: 20pt;
}

.fancy-shadow {
    background-color: rgb(47 36 90 / 29%);
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.fancy-shadow-2 {
    background-color: rgb(255 255 255 / 29%);
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}